import { Box, Flex, Text } from '@chakra-ui/react'
import { MainImage } from 'types'
import ChakraNextImage from './ChakraNextImage'

const BlockCard = ({ uid, image, heading, paragraph, action }: Props) => {
  return (
    <Box height="100%" borderWidth="1px" w="100%" rounded="xl" overflow="hidden" key={uid}>
      <Box
        position="relative"
        height={['50vw', '15vw']}
        flex="none"
        backgroundColor="gray.100"
        borderTopLeftRadius={'xl'}
        borderTopRightRadius={['xl']}
        // borderBottomLeftRadius={['none', 'xl']}
      >
        <ChakraNextImage
          bborderTopLeftRadius={'xl'}
          borderTopRightRadius={['xl']}
          objectFit="cover"
          objectPosition={
            `${image[0]?.focalPoint?.[0] * 100}% ${image[0]?.focalPoint?.[1] * 100}%` || 'center'
          }
          transition="all 0.2s ease-in-out"
          // @ts-ignore
          fill
          alt={image[0]?.title}
          src={image[0]?.path}
          placeholder="blur"
          blurDataURL={image[0]?.imageOptimize?.placeholderImage}
          sizes="(min-width: 30em) 50vw,  100vw"
        />
      </Box>
      <Flex flexDirection={'column'} justifyContent="center" p={[4, 6]} gap={2} minHeight={120}>
        <Box>
          <Text as="h2" fontWeight={'medium'} fontSize={['md', 'xl']}>
            {heading}
          </Text>
          <Text fontSize={['sm', 'md']}>{paragraph}</Text>
          {action}
        </Box>
      </Flex>
    </Box>
  )
}

type Props = {
  uid: string
  image: MainImage
  heading: string
  paragraph: string
  subtitle?: string
  action?: any
}

export default BlockCard
