import { Alert, AlertDescription, AlertTitle, Box, Grid } from '@chakra-ui/react'
import React from 'react'
import { FiAlertCircle } from 'react-icons/fi'

const BlockCallout = ({ calloutTitle, calloutDescription, calloutStatus, uid }: Props) => (
  <Alert status={calloutStatus} px={[4, 8]} py={[4, 6]} key={uid}>
    <Box
      as={FiAlertCircle}
      boxSize={6}
      mr={4}
      //@ts-ignore
      opacity="0.8"
    />
    <Box flex="1">
      <AlertTitle lineHeight="short" fontSize={['sm', 'md']} fontWeight="bold">
        {calloutTitle}
      </AlertTitle>
      {calloutDescription && (
        <AlertDescription mt={1} fontSize={['sm', 'md']}>
          {calloutDescription}
        </AlertDescription>
      )}
    </Box>
  </Alert>
)

type Props = {
  uid: string
  calloutTitle: string
  calloutDescription: string
  calloutStatus: 'error' | 'warning' | 'info' | 'success'
}

export default BlockCallout
