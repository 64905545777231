import { Flex, Text } from '@chakra-ui/react'
import { Campus, MainImage, Service } from '../types'
import constants from '../utils/constants'
import EntryOffer from './EntryOffer'
import EntryPromo from './EntryPromo'
import Scroller from './Scroller'

const BlockPromotional = ({ heading, promotional }: Props) => {
  if (promotional.length === 1) {
    return <EntryPromo width="full" version={2} entry={promotional[0]} key={promotional[0].uid} />
  }

  if (promotional.length === 2) {
    return (
      <Flex gap={[4, 6]} flexWrap={['wrap', 'nowrap']}>
        {promotional.map((entry) => {
          return <EntryPromo width="50%" version={2} entry={entry} key={entry.uid} />
        })}
      </Flex>
    )
  }

  return (
    <Flex
      gap={1}
      flexDir="column"
      sx={{
        '.keen-slider': {
          overflow: 'visible',
        },
      }}
    >
      {heading && (
        <Text as="h3" fontSize={['xl', '2xl']} fontWeight="medium">
          {heading}
        </Text>
      )}
      <Scroller type="promo">
        {promotional.map((entry) => {
          if (
            entry.typeHandle === constants.EntryTypes.Offer ||
            entry.typeHandle === constants.EntryTypes.Offers
          ) {
            return <EntryOffer version={2} offer={entry} key={entry.uid} />
          }
          if (entry.typeHandle === constants.EntryTypes.Promotion) {
            return <EntryPromo version={2} entry={entry} key={entry.uid} />
          }
        })}
      </Scroller>
    </Flex>
  )
}

type Props = {
  heading: string
  promotional: {
    uid: string
    imagePlacement: string
    title: string
    description: string
    linkText: string
    externalLink: string
    timePeriod?: string
    discount?: {
      type: string
      amount: number
    }
    mainImage: MainImage[]
    campus: Campus[]
    service: Service[]
    colorTheme: string
    typeHandle: string
    entryLink: {
      uid: string
      url: string
      uri: string
    }
  }[]
}

export default BlockPromotional
