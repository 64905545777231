import { Flex, Icon, Text } from '@chakra-ui/react'
import { ArrowRight, ArrowUpRight } from 'lucide-react'
import RouterLink from 'next/link'

const BlockCallToAction = ({
  ctaTitle,
  ctaDescription,
  linkToExternalUrl,
  ctaLink,
  colorTheme,
}: Props) => {
  return (
    <RouterLink
      href={ctaLink[0] ? `/${ctaLink[0]?.uri}` : linkToExternalUrl || '#'}
      passHref
      legacyBehavior
    >
      <Flex
        as={'a'}
        target={linkToExternalUrl ? '_blank' : ''}
        backgroundColor={`${colorTheme}.100`}
        gap={6}
        p={[3, 6]}
        justifyContent={'space-between'}
        borderRadius={'xl'}
        sx={{
          transition: 'all 0.2s ease-in-out',
        }}
        _hover={{
          backgroundColor: `${colorTheme}.200`,
        }}
      >
        <Flex flexDirection="column" gap={2}>
          <Text fontWeight={'medium'} fontSize={['md', 'xl']}>
            {ctaTitle}
          </Text>
          {ctaDescription && <Text fontSize={['sm', 'md']}>{ctaDescription}</Text>}
        </Flex>
        <Flex flex={0}>
          <Icon
            as={linkToExternalUrl ? ArrowUpRight : ArrowRight}
            w={6}
            h={6}
            color={`${colorTheme}.400`}
          ></Icon>
        </Flex>
      </Flex>
    </RouterLink>
  )
}

type Props = {
  uid: string
  ctaTitle: string
  ctaDescription?: string
  linkToExternalUrl?: string
  colorTheme: string
  ctaLink?: [
    {
      uid: string
      title: string
      url: string
      uri: string
    }
  ]
  contactInfo?: string
}

export default BlockCallToAction
