import { Flex, Icon, Link, Stack, Text } from '@chakra-ui/react'
import { FiUser } from 'react-icons/fi'
import { MainImage } from 'types'
import ChakraNextImage from './ChakraNextImage'

const BlockPersons = ({ persons }: Props) => {
  if (!persons) return null
  return (
    <Flex flexWrap={'wrap'} gap={[4, 10]}>
      {persons?.map((person) => (
        <Flex
          key={person.uid}
          alignItems="center"
          flexDirection="row"
          flex={['auto', 1]}
          width={['full', null]}
          minWidth={'46%'}
        >
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            borderRadius={'full'}
            position="relative"
            height={[16, 20]}
            width={[16, 20]}
            backgroundColor={'gray.200'}
            mr={4}
            flex={'none'}
          >
            {person.mainImage?.[0] ? (
              <ChakraNextImage
                // @ts-ignore
                fill
                objectFit={'cover'}
                objectPosition={'center'}
                alt={person.title}
                borderRadius={'full'}
                placeholder="blur"
                blurDataURL={person.mainImage?.[0]?.imageOptimize?.placeholderImage}
                src={person.mainImage?.[0]?.path}
                sizes=" (max-width: 768px) 25vw,
                        (max-width: 1200px) 15vw,
                        10vw"
              />
            ) : (
              <Icon color={'gray.400'} width={8} height={8} as={FiUser}></Icon>
            )}
          </Flex>
          <Stack spacing={0}>
            <Text as={'h4'} fontWeight={'medium'} fontSize={['md', 'xl']}>
              {person.title}
            </Text>
            <Text fontSize={['sm', 'md']}>{person.role}</Text>
            <Link
              wordBreak="break-all"
              noOfLines={1}
              fontSize={['sm', 'md']}
              href={`mailto:${person.email}`}
              textDecoration="underline"
            >
              {person.email}
            </Link>
            {person.phoneNumber && (
              <Link
                fontSize={['sm', 'md']}
                textDecoration="underline"
                href={`tel:${person.phoneNumber}`}
              >
                {person.phoneNumber}
              </Link>
            )}
            {person.landlinePhoneNumber && (
              <Link
                fontSize={['sm', 'md']}
                textDecoration="underline"
                href={`tel:${person.landlinePhoneNumber}`}
              >
                {person.landlinePhoneNumber}
              </Link>
            )}
          </Stack>
        </Flex>
      ))}
    </Flex>
  )
}

type Props = {
  persons: {
    uid: string
    title: string
    email: string
    phoneNumber: string
    landlinePhoneNumber: string
    role: string
    department: [
      {
        uid: string
        title: string
      }
    ]
    mainImage: [MainImage]
  }[]
}

export default BlockPersons
