import React from 'react'
import { Box, Flex, Heading } from '@chakra-ui/react'
import capitalize from 'utils/capitalize'
import l10n from 'l10n'
import { filterXSS } from 'xss'

const BlockContactInfo = ({ heading, person, contactLocation }: Props) => {
  const contactPerson = person?.[0]
  const address = contactLocation?.[0]?.richText
  return (
    <Flex
      borderTop={1}
      borderColor="gray.200"
      borderStyle="solid"
      pt={8}
      display={['block', 'flex']}
      w={['100%', '90%', '100%', 3 / 4]}
    >
      <Box w={['100%', 1 / 3]} mb={[4, 0]}>
        <Heading as="h2" fontSize="lg">
          {heading}
        </Heading>
      </Box>
      <Box w={['100%', 2 / 3]}>
        <Heading as="h3" fontSize="md" color="red.900" mb={1}>
          {capitalize(l10n.address)}
        </Heading>
        {!!address && (
          <Box
            dangerouslySetInnerHTML={{
              __html: filterXSS(address),
            }}
          ></Box>
        )}
        {!!contactPerson && (
          <>
            <Heading as="h3" fontSize="md" color="red.900" mt={6} mb={1}>
              {contactPerson.title}
            </Heading>
            <Box>{contactPerson.role}</Box>
            <Box>{contactPerson.email}</Box>
            {(!!contactPerson.email && !!contactPerson.phoneNumber) ||
              (contactPerson.landlinePhoneNumber && '/')}
          </>
        )}
      </Box>
    </Flex>
  )
}

type Props = {
  heading: string
  contactLocation: {
    richText: string
  }
  person: {
    title: string
    role: string
    email: string
    phoneNumber: string
    landlinePhoneNumber: string
  }
}

export default BlockContactInfo
