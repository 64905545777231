import React from 'react'
import {
  FiBell,
  FiBox,
  FiCheckSquare,
  FiDroplet,
  FiHome,
  FiLock,
  FiPocket,
  FiShield,
  FiSpeaker,
  FiSun,
  FiTv,
  FiWifi,
  FiZap,
  FiPhone,
  FiCalendar,
  FiClock,
  FiMapPin,
  FiCompass,
  FiDisc,
  FiGitBranch,
  FiTarget,
  FiChevronsUp,
  FiBatteryCharging,
  FiFeather,
  FiMail,
} from 'react-icons/fi'
import { GrElevator } from 'react-icons/gr'
import {
  Flame,
  Car,
  Dumbbell,
  Accessibility,
  BedDouble,
  BedSingle,
  Baby,
  School,
  ShoppingBag,
  ShoppingBasket,
  TentTree,
  CableCar,
  Hospital,
  GraduationCap,
  University,
  Backpack,
  Utensils,
  Ticket,
  Guitar,
  MicVocal,
  Theater,
  Users,
  User,
  ShoppingCart
} from 'lucide-react'

const Bus = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 5C4 3.34315 5.34315 2 7 2H17C18.6569 2 20 3.34315 20 5V19C20 19.5523 19.5523 20 19 20H5C4.44771 20 4 19.5523 4 19V5Z"
      stroke="#342F2B"
      strokeWidth="2"
    />
    <circle cx="8" cy="17" r="1" fill="#342F2B" />
    <circle cx="16" cy="17" r="1" fill="#342F2B" />
    <path d="M5 14H19" stroke="#342F2B" strokeWidth="2" />
    <path d="M5 6H19" stroke="#342F2B" strokeWidth="2" />
    <rect x="0.5" y="7.5" width="1" height="5" rx="0.5" stroke="#342F2B" />
    <rect x="22.5" y="7.5" width="1" height="5" rx="0.5" stroke="#342F2B" />
    <path d="M8 21V22" stroke="#342F2B" strokeWidth="2" strokeLinecap="round" />
    <path d="M15.5 21V22" stroke="#342F2B" strokeWidth="2" strokeLinecap="round" />
  </svg>
)

const Ski = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#000000"
    viewBox="0 0 256 256"
  >
    <path d="M172,88a28,28,0,1,0-28-28A28,28,0,0,0,172,88Zm0-40a12,12,0,1,1-12,12A12,12,0,0,1,172,48ZM32.32,77.76a8,8,0,0,1,9.92-5.44L112,92.67l10.32-10.33a8,8,0,0,1,11.32,0L163.31,112H200a8,8,0,0,1,0,16H160a8,8,0,0,1-5.66-2.34L148.73,120l-111-32.36A8,8,0,0,1,32.32,77.76ZM236.44,204.24a69.78,69.78,0,0,1-58.37,8.95L21.77,167.67a8,8,0,1,1,4.47-15.36l76,22.13L128.73,148,85.8,135.69a8,8,0,0,1,4.4-15.39l56,16a8,8,0,0,1,3.46,13.35l-29.9,29.9,62.79,18.28a53.78,53.78,0,0,0,45-6.9,8,8,0,1,1,8.88,13.31Z"></path>
  </svg>
)

const Bike = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5" cy="16" r="4" stroke="#342F2B" strokeWidth="2" />
    <circle cx="19" cy="16" r="4" stroke="#342F2B" strokeWidth="2" />
    <path d="M5 15.5L7.5 3H10.5" stroke="#342F2B" strokeWidth="2" strokeLinecap="round" />
    <path d="M14.5 12L15.5 7" stroke="#342F2B" strokeWidth="2" strokeLinecap="round" />
    <path d="M19 16L7 7.5" stroke="#342F2B" strokeWidth="2" strokeLinecap="round" />
    <path d="M13 7L17 7" stroke="#342F2B" strokeWidth="2" strokeLinecap="round" />
  </svg>
)

const Field = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    height="24"
    width="24"
  >
    <path
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M0.75 3.75h22.5v16.5H0.75V3.75Z"
      stroke-width="1.5"
    ></path>
    <path
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 3.75v16.5"
      stroke-width="1.5"
    ></path>
    <path
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M9 12c0 0.7956 0.31607 1.5587 0.87868 2.1213C10.4413 14.6839 11.2044 15 12 15c0.7956 0 1.5587 -0.3161 2.1213 -0.8787C14.6839 13.5587 15 12.7956 15 12c0 -0.7956 -0.3161 -1.5587 -0.8787 -2.12132C13.5587 9.31607 12.7956 9 12 9c-0.7956 0 -1.5587 0.31607 -2.12132 0.87868C9.31607 10.4413 9 11.2044 9 12Z"
      stroke-width="1.5"
    ></path>
    <path
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M0.75 8.25h3c0.39782 0 0.77936 0.15804 1.06066 0.43934 0.2813 0.2813 0.43934 0.66284 0.43934 1.06066v4.5c0 0.3978 -0.15804 0.7794 -0.43934 1.0607S4.14782 15.75 3.75 15.75h-3"
      stroke-width="1.5"
    ></path>
    <path
      stroke="#000000"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M23.25 8.25h-3c-0.3978 0 -0.7794 0.15804 -1.0607 0.43934S18.75 9.35218 18.75 9.75v4.5c0 0.3978 0.158 0.7794 0.4393 1.0607s0.6629 0.4393 1.0607 0.4393h3"
      stroke-width="1.5"
    ></path>
  </svg>
)

const Laundry = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="1" width="18" height="22" rx="1" stroke="#342F2B" strokeWidth="2" />
    <circle cx="12" cy="14" r="5" stroke="#342F2B" strokeWidth="2" />
    <path d="M7 5L13 5" stroke="#342F2B" strokeWidth="2" strokeLinecap="round" />
    <circle cx="17" cy="5" r="1" fill="#342F2B" />
    <path
      d="M7 14L7.64661 13.5689C9.01501 12.6567 10.8371 12.8371 12 14V14C13.1629 15.1629 14.985 15.3433 16.3534 14.4311L17 14"
      stroke="#342F2B"
      strokeWidth="2"
    />
  </svg>
)

const Wheelchair = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#000000"
    viewBox="0 0 256 256"
  >
    <path d="M255.59,189.47a8,8,0,0,0-10.12-5.06l-17.42,5.81-28.9-57.8A8,8,0,0,0,192,128H112V104h56a8,8,0,0,0,0-16H112V79a32,32,0,1,0-16,0V89.81A72,72,0,0,0,112,232c33.52,0,63.69-22.71,71.75-54a8,8,0,1,0-15.5-4C162.09,198,137.91,216,112,216A56,56,0,0,1,96,106.34V136a8,8,0,0,0,8,8h83.05l29.79,59.58a8,8,0,0,0,9.69,4l24-8A8,8,0,0,0,255.59,189.47ZM88,48a16,16,0,1,1,16,16A16,16,0,0,1,88,48Z"></path>
  </svg>
)

const Parking = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="2" width="20" height="20" rx="1" stroke="#342F2B" strokeWidth="2" />
    <path
      d="M9 17V7H12.1484C12.6582 7 13.1279 7.03498 13.5577 7.10495C13.9975 7.16492 14.4123 7.28986 14.8021 7.47976C15.3118 7.71964 15.6867 8.07946 15.9265 8.55922C16.1664 9.02899 16.2864 9.53373 16.2864 10.0735C16.2864 10.3733 16.2464 10.6782 16.1664 10.988C16.0865 11.2879 15.9715 11.5677 15.8216 11.8276C15.6717 12.0875 15.4868 12.3223 15.2669 12.5322C15.047 12.7421 14.7971 12.907 14.5172 13.027C14.1674 13.1669 13.8126 13.2669 13.4528 13.3268C13.093 13.3768 12.7281 13.4018 12.3583 13.4018H10.979V17H9ZM10.979 11.6777H12.1034C12.3933 11.6777 12.6532 11.6627 12.8831 11.6327C13.1229 11.6027 13.3278 11.5477 13.4978 11.4678C13.7876 11.3378 13.9925 11.1579 14.1124 10.928C14.2324 10.6982 14.2924 10.4533 14.2924 10.1934C14.2924 9.91354 14.2224 9.65367 14.0825 9.41379C13.9525 9.17391 13.7426 9.004 13.4528 8.90405C13.2829 8.84408 13.1029 8.7991 12.913 8.76912C12.7231 8.73913 12.4783 8.72414 12.1784 8.72414H10.979V11.6777Z"
      fill="#342F2B"
    />
  </svg>
)

const Stroller = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#000000"
    viewBox="0 0 256 256"
  >
    <path d="M160,32h-8a16,16,0,0,0-16,16v56H55.2A40.07,40.07,0,0,0,16,72a8,8,0,0,0,0,16,24,24,0,0,1,24,24,80.09,80.09,0,0,0,80,80h40a80,80,0,0,0,0-160Zm63.48,72H166.81l41.86-33.49A63.73,63.73,0,0,1,223.48,104ZM160,48a63.59,63.59,0,0,1,36.69,11.61L152,95.35V48Zm0,128H120a64.09,64.09,0,0,1-63.5-56h167A64.09,64.09,0,0,1,160,176Zm-56,48a16,16,0,1,1-16-16A16,16,0,0,1,104,224Zm104,0a16,16,0,1,1-16-16A16,16,0,0,1,208,224Z"></path>
  </svg>
)

const Users3 = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#000000"
    viewBox="0 0 256 256"
  >
    <path d="M244.8,150.4a8,8,0,0,1-11.2-1.6A51.6,51.6,0,0,0,192,128a8,8,0,0,1-7.37-4.89,8,8,0,0,1,0-6.22A8,8,0,0,1,192,112a24,24,0,1,0-23.24-30,8,8,0,1,1-15.5-4A40,40,0,1,1,219,117.51a67.94,67.94,0,0,1,27.43,21.68A8,8,0,0,1,244.8,150.4ZM190.92,212a8,8,0,1,1-13.84,8,57,57,0,0,0-98.16,0,8,8,0,1,1-13.84-8,72.06,72.06,0,0,1,33.74-29.92,48,48,0,1,1,58.36,0A72.06,72.06,0,0,1,190.92,212ZM128,176a32,32,0,1,0-32-32A32,32,0,0,0,128,176ZM72,120a8,8,0,0,0-8-8A24,24,0,1,1,87.24,82a8,8,0,1,0,15.5-4A40,40,0,1,0,37,117.51,67.94,67.94,0,0,0,9.6,139.19a8,8,0,1,0,12.8,9.61A51.6,51.6,0,0,1,64,128,8,8,0,0,0,72,120Z"></path>
  </svg>
)

const Users4 = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="#000000"
    viewBox="0 0 256 256"
  >
    <path d="M27.2,126.4a8,8,0,0,0,11.2-1.6,52,52,0,0,1,83.2,0,8,8,0,0,0,11.2,1.59,7.73,7.73,0,0,0,1.59-1.59h0a52,52,0,0,1,83.2,0,8,8,0,0,0,12.8-9.61A67.85,67.85,0,0,0,203,93.51a40,40,0,1,0-53.94,0,67.27,67.27,0,0,0-21,14.31,67.27,67.27,0,0,0-21-14.31,40,40,0,1,0-53.94,0A67.88,67.88,0,0,0,25.6,115.2,8,8,0,0,0,27.2,126.4ZM176,40a24,24,0,1,1-24,24A24,24,0,0,1,176,40ZM80,40A24,24,0,1,1,56,64,24,24,0,0,1,80,40ZM203,197.51a40,40,0,1,0-53.94,0,67.27,67.27,0,0,0-21,14.31,67.27,67.27,0,0,0-21-14.31,40,40,0,1,0-53.94,0A67.88,67.88,0,0,0,25.6,219.2a8,8,0,1,0,12.8,9.6,52,52,0,0,1,83.2,0,8,8,0,0,0,11.2,1.59,7.73,7.73,0,0,0,1.59-1.59h0a52,52,0,0,1,83.2,0,8,8,0,0,0,12.8-9.61A67.85,67.85,0,0,0,203,197.51ZM80,144a24,24,0,1,1-24,24A24,24,0,0,1,80,144Zm96,0a24,24,0,1,1-24,24A24,24,0,0,1,176,144Z"></path>
  </svg>
)

const Animal = (props) => (
  <svg
    {...props}
    width="27"
    height="24"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.148 12.8699C11.6052 12.3188 12.284 12 13 12C13.716 12 14.3948 12.3188 14.852 12.8699L18.9663 17.8291C19.5131 18.4882 19.6578 19.3928 19.3438 20.1896L20.2742 20.5562L19.3438 20.1896L19.2357 20.4639C18.9026 21.3091 18.0353 21.8175 17.135 21.6953C14.3909 21.3226 11.6091 21.3226 8.86497 21.6953C7.96473 21.8175 7.0974 21.3091 6.76431 20.4639L6.65621 20.1896C6.34222 19.3928 6.48688 18.4882 7.03371 17.8291L11.148 12.8699Z"
      stroke="#342F2B"
      strokeWidth="2"
    />
    <path
      d="M6.20513 10.7476C7.0744 12.2532 6.63432 13.6752 5.84808 14.1292C5.06184 14.5831 3.6103 14.2532 2.74103 12.7476C1.87175 11.242 2.31184 9.81996 3.09808 9.36603C3.88432 8.91209 5.33585 9.24197 6.20513 10.7476Z"
      stroke="#342F2B"
      strokeWidth="2"
    />
    <path
      d="M11.4952 5.09701C11.6661 6.06663 11.515 6.96021 11.179 7.61435C10.8433 8.26772 10.3587 8.63121 9.84947 8.72101C9.34019 8.81081 8.76052 8.63497 8.22165 8.1358C7.68213 7.63605 7.2345 6.84805 7.06353 5.87843C6.89256 4.90881 7.04369 4.01523 7.37974 3.36109C7.71539 2.70772 8.19995 2.34424 8.70923 2.25444C9.21851 2.16464 9.79818 2.34047 10.3371 2.83964C10.8766 3.33939 11.3242 4.1274 11.4952 5.09701Z"
      stroke="#342F2B"
      strokeWidth="2"
    />
    <path
      d="M20.0185 10.7476C19.1492 12.2532 19.5893 13.6752 20.3756 14.1292C21.1618 14.5831 22.6133 14.2532 23.4826 12.7476C24.3519 11.242 23.9118 9.81996 23.1256 9.36603C22.3393 8.91209 20.8878 9.24197 20.0185 10.7476Z"
      stroke="#342F2B"
      strokeWidth="2"
    />
    <path
      d="M14.7285 5.09701C14.5575 6.06663 14.7086 6.96021 15.0447 7.61435C15.3803 8.26772 15.8649 8.63121 16.3742 8.72101C16.8834 8.81081 17.4631 8.63497 18.002 8.1358C18.5415 7.63605 18.9891 6.84805 19.1601 5.87843C19.3311 4.90881 19.1799 4.01523 18.8439 3.36109C18.5082 2.70772 18.0237 2.34424 17.5144 2.25444C17.0051 2.16464 16.4255 2.34047 15.8866 2.83964C15.3471 3.33939 14.8994 4.1274 14.7285 5.09701Z"
      stroke="#342F2B"
      strokeWidth="2"
    />
  </svg>
)

// icons for the housing portal
export const namesIcons = [
  {
    name: 'badstue',
    icon: 'droplet',
  },
  {
    name: 'sauna',
    icon: 'droplet',
  },
  {
    name: 'balcony',
    icon: 'feather',
  },
  {
    name: 'veranda',
    icon: 'feather',
  },
  {
    name: 'block heater',
    icon: 'sun',
  },
  {
    name: 'bod',
    icon: 'box',
  },
  {
    name: 'storage',
    icon: 'box',
  },
  {
    name: 'heis',
    icon: 'elevator',
  },
  {
    name: 'elevator',
    icon: 'elevator',
  },
  {
    name: 'gratis trening',
    icon: 'dumbbell',
  },
  {
    name: 'free gym',
    icon: 'dumbbell',
  },
  {
    name: 'husdyr tillatt',
    icon: 'animal',
  },
  {
    name: 'pets allowed',
    icon: 'animal',
  },
  {
    name: 'lift',
    icon: 'chevronUp',
  },
  {
    name: 'motorvarmer',
    icon: 'car',
  },
  {
    name: 'engine heater',
    icon: 'car',
  },
  {
    name: 'mulighet for elbillading',
    icon: 'zap',
  },
  {
    name: 'possibility for electric car charging',
    icon: 'zap',
  },
  {
    name: 'parkering',
    icon: 'parking',
  },
  {
    name: 'parking',
    icon: 'parking',
  },

  {
    name: 'sykkelgarasje',
    icon: 'bike',
  },
  {
    name: 'bike storage',
    icon: 'bike',
  },
  {
    name: 'vaskeri',
    icon: 'laundry',
  },
  {
    name: 'vaskerom',
    icon: 'laundry',
  },
  {
    name: 'laundry',
    icon: 'laundry',
  },
  {
    name: 'vedovn',
    icon: 'flame',
  },
  {
    name: 'wood-burning stove',
    icon: 'flame',
  },
  {
    name: 'accessibility',
    icon: 'accessibility',
  },
  {
    name: 'couple',
    icon: 'couple',
  },
  {
    name: 'single',
    icon: 'single',
  },
  {
    name: 'baby',
    icon: 'baby',
  },
  {
    name: 'school',
    icon: 'school',
  },
  {
    name: 'shoppingBag',
    icon: 'shoppingBag',
  },
  {
    name: 'shoppingBasket',
    icon: 'shoppingBasket',
  },
  {
    name: 'natureArea',
    icon: 'natureArea',
  },
  {
    name: 'cableCar',
    icon: 'cableCar',
  },
  {
    name: 'hospital',
    icon: 'hospital',
  },
  {
    name: 'graduationCap',
    icon: 'graduationCap',
  },
  {
    name: 'university',
    icon: 'university',
  },
  {
    name: 'backpack',
    icon: 'backpack',
  },
  {
    name: 'utensils',
    icon: 'utensils',
  },
  {
    name: 'ticket',
    icon: 'ticket',
  },
  {
    name: 'guitar',
    icon: 'guitar',
  },
  {
    name: 'micVocal',
    icon: 'micVocal',
  },
  {
    name: 'theater',
    icon: 'theater',
  },
  {
    name: 'field',
    icon: 'field',
  },
  {
    name: 'ski',
    icon: 'ski',
  },
  {
    name: 'users',
    icon: 'users',
  },
  {
    name: 'user',
    icon: 'user',
  },
  {
    name: 'wheelchair',
    icon: 'wheelchair',
  },
  {
    name: 'stroller',
    icon: 'stroller',
  },
  {
    name: 'users3',
    icon: 'users3',
  },
  {
    name: 'users4',
    icon: 'users4',
  },
  {
    name: 'shoppingCart',
    icon: 'shoppingCart',
  },
]

export const icons = {
  users3: Users3,
  users4: Users4,
  stroller: Stroller,
  wheelchair: Wheelchair,
  shoppingCart: ShoppingCart,
  couple: BedDouble,
  users: Users,
  user: User,
  baby: Baby,
  ski: Ski,
  school: School,
  single: BedSingle,
  natureArea: TentTree,
  cableCar: CableCar,
  hospital: Hospital,
  backpack: Backpack,
  utensils: Utensils,
  ticket: Ticket,
  guitar: Guitar,
  micVocal: MicVocal,
  theater: Theater,
  field: Field,
  university: University,
  graduationCap: GraduationCap,
  shoppingBag: ShoppingBag,
  shoppingBasket: ShoppingBasket,
  accessibility: Accessibility,
  checkSquare: FiCheckSquare,
  wifi: FiWifi,
  zap: FiZap,
  box: FiBox,
  bell: FiBell,
  speaker: FiSpeaker,
  tv: FiTv,
  home: FiHome,
  lock: FiLock,
  pocket: FiPocket,
  shield: FiShield,
  droplet: FiDroplet,
  sun: FiSun,
  bus: Bus,
  bike: Bike,
  laundry: Laundry,
  parking: Parking,
  animal: Animal,
  phone: FiPhone,
  calendar: FiCalendar,
  clock: FiClock,
  mapPin: FiMapPin,
  compass: FiCompass,
  disc: FiDisc,
  gitBranch: FiGitBranch,
  target: FiTarget,
  chevronUp: FiChevronsUp,
  battery: FiBatteryCharging,
  feather: FiFeather,
  elevator: GrElevator,
  email: FiMail,
  flame: Flame,
  car: Car,
  dumbbell: Dumbbell,
}
