import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
} from '@chakra-ui/react'
import * as React from 'react'
import RichText from './RichText'

const BlockAccordion = ({ uid, accordionTitle, accordionText }: Props) => {
  return (
    <Accordion allowMultiple key={uid}>
      <AccordionItem>
        <AccordionButton p={[3, 6]}>
          <Flex justifyContent="space-between" width="100%" alignItems="center" textAlign="left">
            <Text fontWeight="medium" fontSize={['md', 'xl']}>
              {accordionTitle}
            </Text>
            <AccordionIcon color="orange.400" />
          </Flex>
        </AccordionButton>
        <AccordionPanel>
          <RichText maxWidth="100%">{accordionText}</RichText>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

type Props = {
  uid: string
  accordionTitle: string
  accordionText: string
}

export default BlockAccordion
