import { Box, Flex, Text } from '@chakra-ui/react'
import { FiCheck } from 'react-icons/fi'

const BlockFeatures = ({ uid, feature }: Props) => {
  return (
    <Flex gap={[4, 6]} flexDirection="column">
      {feature?.map((item) => (
        <Flex key={`${item.featureTitle}-${uid}`} gap={2}>
          <Box as={FiCheck} width={[6, 8]} height={[6, 8]} color="orange.400" />
          <Box flex={1}>
            <Text fontWeight={'medium'} fontSize={['lg', 'xl']}>
              {item.featureTitle}
            </Text>
            <Text fontSize={['md', 'lg']}>{item.featureDescription}</Text>
          </Box>
        </Flex>
      ))}
    </Flex>
  )
}

type Props = {
  uid: string
  feature: { featureTitle: string; featureDescription: string }[]
}

export default BlockFeatures
