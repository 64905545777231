import { Box, Divider, Flex, List, ListItem, Text } from '@chakra-ui/react'
import React from 'react'
import { icons, namesIcons } from 'utils/icons'

const alterNames = (smallName) => {
  if (smallName === 'parking' || smallName === 'laundry') {
    return smallName
  } else {
    let returnIcon = namesIcons
      .map((item) => {
        if (item.name === smallName) return item.icon
      })
      .filter((item) => item)
    return returnIcon
  }
}

const BlockSymbolList = ({ uid, symbolListTitle, symbols, facilities }: Props) => (
  <Box key={uid}>
    {symbols && <Divider mb={10} mt={4} />}
    <Text
      lineHeight="short"
      fontFamily={'heading'}
      fontSize={['2xl', '3xl']}
      mb={[4, 6]}
      fontWeight="medium-display"
    >
      {symbolListTitle}
    </Text>
    {/* for the page builder */}
    {symbols && (
      <>
        <List as="dl" display="flex" flexWrap="wrap">
          {symbols?.map((row, index) => (
            <Flex key={index} width={['100%', '50%']}>
              {row.symbol !== 'none' && (
                <Box
                  as={icons[row.symbol]}
                  width={6}
                  height={6}
                  mr={4}
                  mt={['0px', '2px']} // Offset for text's line height
                />
              )}
              <Box
                flex="1"
                ml={
                  symbols[index - 1]?.symbol &&
                  symbols[index - 1]?.symbol !== 'none' &&
                  row.symbol === 'none'
                    ? 8
                    : null
                }
              >
                <ListItem mb={[4, 6]} fontSize={['sm', 'md']} as="dt" color="gray.700">
                  {row.title}
                </ListItem>
              </Box>
            </Flex>
          ))}
        </List>
        <Divider mb={6} mt={8} />
      </>
    )}

    {/* for the housing portal */}
    {facilities && (
      <>
        <List as="dl" display="flex" flexWrap="wrap">
          {facilities?.map((row, index) => (
            <Flex key={index} width={['100%', '50%']}>
              {row.title !== 'none' && (
                <Box
                  as={icons[alterNames(row.title.toLowerCase())]}
                  width={6}
                  height={6}
                  mr={6}
                  mt={['0px', '2px']}
                />
              )}
              <Box flex="1">
                <ListItem mb={[4, 6]} fontSize={['md']} as="dt">
                  {row.title}
                </ListItem>
              </Box>
            </Flex>
          ))}
        </List>
      </>
    )}
  </Box>
)

type Props = {
  uid: string
  symbolListTitle: string
  symbols?: [
    {
      title: string
      symbol: string
    }
  ]
  facilities?: any
}

export default BlockSymbolList
