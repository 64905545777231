import { Box } from '@chakra-ui/react'
import dynamic from 'next/dynamic'

const DynamicIframeResizer = dynamic(() => import('./IframeResizer'), {
  ssr: false,
})

const BlockForm = ({ uid, form }: Props) => (
  <Box borderWidth="1px" w="100%" rounded="md" overflow="hidden" key={uid}>
    <DynamicIframeResizer
      src={`${process.env.NEXT_PUBLIC_APP_CMS_URL_ROOT}/formie?handle=${form[0].handle}`}
    />
  </Box>
)

type Props = {
  uid: string
  form: any
}

export default BlockForm
