import * as React from 'react'
import { Box, Text } from '@chakra-ui/react'
import RichText from './RichText'

const BlockRichText = ({ headline, text, uid }: Props) => {
  return (
    <Box key={uid}>
      {headline && (
        <Text
          fontSize={['2xl', '3xl']}
          lineHeight="short"
          mb={[1, 2]}
          fontFamily="heading"
          fontWeight="medium-display"
          as="h2"
        >
          {headline}
        </Text>
      )}
      <RichText>{text}</RichText>
    </Box>
  )
}

type Props = {
  uid: string
  headline: string
  text: string
}

export default BlockRichText
