import { Box, Image as CImage, Text } from '@chakra-ui/react'
import Image from 'next/image'
import { Image as ImageType } from 'types'
import Scroller from './Scroller'

const SingleImage = ({ image }: { image: ImageType }) => {
  return (
    <Box as="figure" key={image.uid}>
      <Image
        style={{ width: '100%' }}
        src={image.path}
        alt={image.title}
        width={image.width}
        height={image.height}
        placeholder="blur"
        blurDataURL={image.imageOptimize?.placeholderImage}
        sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
      />
      {image.imageCaption && (
        <Text as="figcaption" lineHeight="shorter" fontSize={['sm']} mt={3}>
          {image.imageCaption}
        </Text>
      )}
    </Box>
  )
}

const BlockImages = ({ images }: Props) => {
  if (!images) return <></>

  if (images.length === 1) {
    const image = images[0]
    return <SingleImage image={image} />
  }

  return (
    <Box
      sx={{
        '.keen-slider': {
          overflow: 'visible',
        },
      }}
    >
      <Scroller pb={0} pt={0}>
        {images?.map((image) => {
          return (
            <Box
              className="keen-slider__slide"
              key={image.uid}
              sx={{
                overflow: 'visible !important',
                width: 'auto !important',
              }}
            >
              <Box as="figure" display={'block'} m={0}>
                <CImage
                  maxWidth={'none'}
                  display={'block'}
                  width={'auto'}
                  height={['14rem', '26rem']}
                  src={`https://samskipnaden.imgix.net/${image.path}?w=${800}&auto=compress,format`}
                  alt={image.title}
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 80vw, 50vw"
                  srcSet={`
                    https://samskipnaden.imgix.net/${
                      image.path
                    }?w=${1920}&auto=compress,format 1920w,
                    https://samskipnaden.imgix.net/${
                      image.path
                    }?w=${1200}&auto=compress,format 1200w,
                    https://samskipnaden.imgix.net/${
                      image.path
                    }?w=${1080}&auto=compress,format 1080w,
                    https://samskipnaden.imgix.net/${image.path}?w=${750}&auto=compress,format 750w,
                    https://samskipnaden.imgix.net/${image.path}?w=${640}&auto=compress,format 640w,
                  `}
                  fallbackSrc={image.imageOptimize?.placeholderImage}
                  loading="lazy"
                />
              </Box>
              {image.imageCaption && (
                <Text as="figcaption" lineHeight="shorter" fontSize={['sm']} mt={3}>
                  {image.imageCaption}
                </Text>
              )}
            </Box>
          )
        })}
      </Scroller>
    </Box>
  )
}

type Props = {
  images: ImageType[]
}

export default BlockImages
