import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import RichText from './RichText'

const BlockInfoBox = ({ heading, text }: Props) => {
  return (
    <Flex flexDir="column" px={[3, 6]} py={[3, 6]} backgroundColor="blue.50">
      <Text as={'h3'} fontWeight={'medium'} fontSize={['md', 'xl']}>
        {heading}
      </Text>
      <RichText>{text}</RichText>
    </Flex>
  )
}

type Props = {
  heading: string
  text: string
}

export default BlockInfoBox
