import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import RichText from './RichText'
import ChakraNextImage from './ChakraNextImage'

const BlockInfoBox = ({ quoteName, quoteText, quotePerson }: Props) => {
  const person = quotePerson?.[0]

  return (
    <Flex flexDir="column">
      <Text as={'p'} fontSize={['xl', '2xl']} lineHeight={'short'} fontWeight={'semibold'}>
        &#8220;{quoteText}&#8221;
      </Text>
      {quoteName && quoteName?.length > 1 && <Text mt={[2, 4]}>– {quoteName}</Text>}
      {person && (
        <Flex alignItems={'center'} gap={[2, 3]} mt={[3, 5]}>
          {person && person.mainImage && (
            <Box
              borderRadius={'full'}
              height={[8, 10]}
              width={[8, 10]}
              backgroundColor={'gray.200'}
              position={'relative'}
            >
              <ChakraNextImage
                //@ts-ignore
                fill
                objectFit={'cover'}
                objectPosition={'center'}
                alt={person.title}
                borderRadius={'full'}
                placeholder="blur"
                blurDataURL={person.mainImage?.[0]?.imageOptimize?.placeholderImage}
                src={person.mainImage?.[0]?.path}
                sizes=" (max-width: 768px) 6vw,
                        (max-width: 1200px) 6vw,
                        6vw"
              />
            </Box>
          )}
          <Text>{person?.title}</Text>
        </Flex>
      )}
    </Flex>
  )
}

type Props = {
  quoteText: string
  quoteName?: string
  quotePerson?: any
}

export default BlockInfoBox
