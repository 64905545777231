import Sidebar from 'components/Sidebar'
import React from 'react'
import constants from '../utils/constants'
import BlockAccordion from './BlockAccordion'
import BlockArticles from './BlockArticles'
import BlockCallout from './BlockCallout'
import BlockCallToAction from './BlockCallToAction'
import BlockCard from './BlockCard'
import BlockContactInfo from './BlockContactInfo'
import BlockDescriptionList from './BlockDescriptionList'
import BlockDownloads from './BlockDownloads'
import BlockEmbed from './BlockEmbed'
import BlockFeatures from './BlockFeatures'
import BlockForm from './BlockForm'
import BlockImages from './BlockImages'
import BlockInfoBox from './BlockInfoBox'
import BlockMap from './BlockMap'
import BlockPersons from './BlockPersons'
import BlockPromotional from './BlockPromotional'
import BlockRichText from './BlockRichText'
import BlockSymbolList from './BlockSymbolList'
import BlockTable from './BlockTable'
import GridContainer from './GridContainer'
import GridItem from './GridItem'
import { PageBuilder } from 'types'
import BlockQuote from './BlockQuote'

const BlocksPageBuilder = ({ shortAddress, blocks, entryType }: Props) => {
  const renderBlock = (block, index: number) => {
    const prevBlock = blocks[index - 1]?.typeHandle

    switch (block.typeHandle) {
      case constants.BlockTypes.RichText:
        return (
          <GridItem key={block.uid}>
            <BlockRichText {...block} />
          </GridItem>
        )
      case constants.BlockTypes.Callout:
        return (
          <GridItem key={block.uid}>
            <BlockCallout {...block} />
          </GridItem>
        )
      case constants.BlockTypes.DescriptionList:
        return (
          <GridItem key={block.uid}>
            <BlockDescriptionList {...block} />
          </GridItem>
        )
      case constants.BlockTypes.Features:
        return (
          <GridItem key={block.uid}>
            <BlockFeatures {...block} />
          </GridItem>
        )
      case constants.BlockTypes.SymbolList:
        return (
          <GridItem key={block.uid}>
            <BlockSymbolList {...block} />
          </GridItem>
        )
      case constants.BlockTypes.Table:
        return (
          <GridItem key={block.uid}>
            <BlockTable {...block} />
          </GridItem>
        )
      case constants.BlockTypes.Images:
        let gridItemProps = { colSpan: [12, null, null, 8], colStart: [0, null, null, 3] }
        if (block.images?.length === 1) {
          // Check if image is portrait and adjust grid placement
          if (block.images[0]?.height > block.images[0]?.width) {
            gridItemProps = { colSpan: [12, null, null, 6], colStart: [0, null, null, 4] }
          }
        }
        return (
          <GridItem key={block.uid} {...gridItemProps}>
            <BlockImages {...block} />
          </GridItem>
        )
      case constants.BlockTypes.Embed:
        return (
          <GridItem key={block.uid}>
            <BlockEmbed {...block} />
          </GridItem>
        )
      case constants.BlockTypes.ContactInfo:
        return (
          <GridItem key={block.uid}>
            <BlockContactInfo {...block} />
          </GridItem>
        )
      case constants.BlockTypes.InfoBox:
        return (
          <GridItem key={block.uid}>
            <BlockInfoBox {...block} />
          </GridItem>
        )
      case constants.BlockTypes.Downloads:
        return (
          <GridItem key={block.uid}>
            <BlockDownloads {...block} />
          </GridItem>
        )
      case constants.BlockTypes.Persons:
        return <GridItem key={block.uid}>{<BlockPersons {...block} />}</GridItem>
      case constants.BlockTypes.Map:
        return (
          <GridItem key={block.uid}>
            <BlockMap {...block} shortAddress={shortAddress} />
          </GridItem>
        )
      case constants.BlockTypes.Promotional:
        let pGridItemProps = { colSpan: [12, null, null, 8], colStart: [0, null, null, 3] }
        if (entryType === 'frontpage') {
          pGridItemProps = { colSpan: [12], colStart: [1] }
        }
        return (
          <GridItem key={block.uid} {...pGridItemProps}>
            <BlockPromotional {...block} />
          </GridItem>
        )
      case constants.BlockTypes.CallToAction:
        return (
          <GridItem key={block.uid}>
            <BlockCallToAction {...block} />
          </GridItem>
        )
      case constants.BlockTypes.Form:
        return (
          <GridItem key={block.uid}>
            <BlockForm {...block} />
          </GridItem>
        )

      case constants.BlockTypes.Card:
        return (
          <GridItem key={block.uid} colSpan={[12, 12, 6, 4]} colStart={[1, 1, null, null]}>
            <BlockCard {...block} />
          </GridItem>
        )

      case constants.BlockTypes.Quote:
        return (
          <GridItem key={block.uid}>
            <BlockQuote {...block} />
          </GridItem>
        )

      case constants.BlockTypes.Accordion:
        return (
          <GridItem key={block.uid} mt={prevBlock !== 'accordion' ? 0 : ['-33px', '-49px']}>
            <BlockAccordion {...block} />
          </GridItem>
        )

      case constants.BlockTypes.Articles:
        return (
          <GridItem key={block.uid}>
            <BlockArticles {...block} />
          </GridItem>
        )
      default:
        return <></>
    }
  }

  if (!blocks || !blocks.length) return <></>

  return (
    <GridContainer rowGap={[8, 12]} mt={6}>
      {blocks?.map((block, index) => renderBlock(block, index))}
    </GridContainer>
  )
}

type Props = {
  blocks: [PageBuilder]
  entryType?: string
  shortAddress?: any
}

export default BlocksPageBuilder
