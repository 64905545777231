import { Box, LinkBox, LinkOverlay } from '@chakra-ui/react'
import Image from 'next/image'
import RouterLink from 'next/link'
import { Article } from 'types'

const ArticleCard = ({ uri, mainImage, title }: Props) => {
  const isGif = mainImage?.[0]?.path?.includes('.gif')
  const image = mainImage?.[0]
  return (
    <LinkBox as="article" _hover={{ textDecoration: 'underline' }}>
      {image ? (
        <Image
          unoptimized={isGif}
          alt={image?.title}
          src={isGif ? image?.url : image?.path}
          width={image?.width}
          height={image?.height}
          placeholder="blur"
          blurDataURL={image?.imageOptimize?.placeholderImage}
          sizes="(max-width: 768px) 50vw,
                (max-width: 1200px) 33vw,
                25vw"
        />
      ) : (
        <Box height={'200px'} bgColor={'gray.200'} />
      )}
      <RouterLink href={`/${uri}`} passHref legacyBehavior>
        <LinkOverlay
          fontFamily="body"
          fontWeight="medium"
          fontSize={['md', 'lg', 'xl']}
          lineHeight="shorter"
          color="black"
          mt={4}
          display="block"
        >
          {title}
        </LinkOverlay>
      </RouterLink>
    </LinkBox>
  )
}

type Props = {} & Article

export default ArticleCard
