import { Alert, AlertDescription, AlertTitle, Box, Grid } from '@chakra-ui/react'
import React from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import LatestNews from './LatestNews'

const BlockArticles = ({ entry, header, uid }: Props) => {
  return (
    <LatestNews
      uid={uid}
      entries={entry}
      column={12 / entry.length}
      campusLength={entry.campusCategory?.length}
      title={header}
      module
    />
  )
}

type Props = {
  header: string
  entry: any
  uid: string
}

export default BlockArticles
