import { Grid, Text, Box } from '@chakra-ui/react'
import React from 'react'
import ArticleCard from './ArticleCard'
import GridContainer from './GridContainer'

const LatestNews = ({
  entries,
  column,
  campusLength,
  marginBottom,
  module = false,
  colorTheme,
  uid,
  title = 'Aktuelt fra Samskipnaden',
  ...rest
}: Props) => {
  return (
    <Box bg={`${colorTheme}.100`} key={uid} py={12}>
      <GridContainer rowGap={16} alignItems="start">
        <Box width="full" margin="0 auto">
          <Text
            as="h3"
            color={'gray.800'}
            fontWeight="bold"
            fontSize={['lg', null, 'xl']}
            lineHeight="shorter"
            mb={6}
          >
            {title}
          </Text>
          <Grid
            justifyContent={'center'}
            templateColumns="repeat(12, 1fr)"
            gap={[6]}
            alignContent={'center'}
          >
            {entries?.map((entry, id) => (
              <ArticleCard
                key={entry.uid}
                uid={entry.uid}
                {...entry}
                column={
                  column === 4 && id > 0
                    ? 3
                    : column === 4 && id === 0
                    ? 6
                    : column === 12
                    ? 6
                    : column
                }
                campusLength={campusLength}
              />
            ))}
          </Grid>
        </Box>
      </GridContainer>
    </Box>
  )
}

type Props = {
  color?: string
  entries?: any
  column?: number
  campusLength?: number
  marginBottom?: any
  title?: string
  module?: boolean
  colorTheme?: any
  uid?: number | string
}

export default LatestNews
