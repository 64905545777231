import { AspectRatio, Box } from '@chakra-ui/react'

const BlockEmbed = ({ embedUrl, uid }: Props) => (
  <AspectRatio ratio={embedUrl.width / embedUrl.height} key={uid}>
    <Box
      sx={{
        iframe: {
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          position: 'absolute',
        },
      }}
      dangerouslySetInnerHTML={{
        __html: embedUrl.code,
      }}
    ></Box>
  </AspectRatio>
)

type Props = {
  uid: string
  embedUrl: {
    code: string
    width: number
    height: number
  }
}

export default BlockEmbed
