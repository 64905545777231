import { Box, Divider, Flex, Text, Grid, GridItem } from '@chakra-ui/react'
import Dot from './Dot'
import React from 'react'

const BlockTable = ({ uid, rows, divider = true }: Props) => (
  <Box key={uid}>
    {divider && <Divider mb={10} mt={4} />}
    <Grid templateColumns="repeat(1, 2fr)">
      {rows?.map((item, i) => (
        <GridItem key={i}>
          <Flex
            justifyContent="space-between"
            bgColor={i % 2 == 0 ? 'brown.50' : 'white'}
            w="100%"
            borderRadius="lg"
            p={2}
          >
            <Text>{item.text}</Text>
            <Text
              as="p"
              fontWeight="700"
              fontSize={['sm', 'sm']}
              lineHeight="shorter"
              color="black"
            >
              {item.value}
            </Text>
          </Flex>
        </GridItem>
      ))}
    </Grid>
  </Box>
)

type Props = {
  uid: string
  rows: any
  divider?: boolean
}

export default BlockTable
