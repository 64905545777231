import { List, ListItem, Text, Box, Divider, Flex, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { icons } from '../utils/icons'

const DescriptionList = ({
  uid,
  descriptionListTitle,
  descriptionList,
  layout,
  backgroundColor,
}: Props) => {
  if (layout === 'grid') {
    return (
      <Box
        key={uid}
        my={6}
        backgroundColor={backgroundColor ? 'blue.50' : 'none'}
        px={backgroundColor ? 6 : 0}
        py={[backgroundColor ? 6 : 0, backgroundColor ? 8 : 0]}
      >
        <Text as={'h3'} fontWeight={'medium'} fontSize={['md', 'xl']}>
          {descriptionListTitle}
        </Text>
        <List as={SimpleGrid} columns={[2, 2, 3]} spacingX={[2]} spacingY={1}>
          {descriptionList?.map((row, index) => (
            <Flex key={index} mt={[4, 6]}>
              {row.symbol !== 'none' && (
                <Box
                  as={icons[row.symbol]}
                  width={5}
                  height={5}
                  mr={[4, 3]}
                  mt={['2px', '4px']} // Offset for text's line height
                />
              )}
              <Box
                flex="1"
                ml={
                  descriptionList[index - 1]?.symbol &&
                  descriptionList[index - 1]?.symbol !== 'none' &&
                  row.symbol === 'none'
                    ? 8
                    : null
                }
              >
                <ListItem fontSize={['md', 'lg']} as="dt" fontWeight={'medium'}>
                  {row.term}
                </ListItem>
                <ListItem fontSize={['md', 'lg']} as="dd">
                  {row.description}
                </ListItem>
              </Box>
            </Flex>
          ))}
        </List>
      </Box>
    )
  }
  return (
    <Box key={uid} my={6}>
      <Text as={'h3'} fontWeight={'medium'} fontSize={['md', 'xl']}>
        {descriptionListTitle}
      </Text>
      <List as={'dl'}>
        {descriptionList?.map((row, index) => (
          <Flex key={index} mt={[4, 6]}>
            {row.symbol !== 'none' && (
              <Box
                as={icons[row.symbol]}
                width={5}
                height={5}
                mr={4}
                mt={['2px', '4px']} // Offset for text's line height
              />
            )}
            <Box
              flex="1"
              ml={
                descriptionList[index - 1]?.symbol &&
                descriptionList[index - 1]?.symbol !== 'none' &&
                row.symbol === 'none'
                  ? 8
                  : null
              }
            >
              <ListItem fontSize={['md', 'lg']} as="dt" fontWeight={'medium'}>
                {row.term}
              </ListItem>
              <ListItem fontSize={['md', 'lg']} as="dd">
                {row.description}
              </ListItem>
            </Box>
          </Flex>
        ))}
      </List>
    </Box>
  )
}

type Props = {
  uid: string
  descriptionListTitle: string
  layout: string
  backgroundColor: boolean
  descriptionList: {
    term: string
    description: string
    symbol: string
  }[]
}

export default DescriptionList
