import { List, ListItem, ListIcon, Link, Flex, Box, Text, Icon } from '@chakra-ui/react'
import { FileDown } from 'lucide-react'
import React from 'react'
import { FiDownload } from 'react-icons/fi'

const BlockDownloads = ({ uid, heading, downloadFiles }: Props) => (
  <>
    {heading && (
      <Text fontWeight={'medium'} fontSize={['md', 'xl']} mb={[4, 6]}>
        {heading}
      </Text>
    )}
    <Flex gap={[3, 4]} flexDirection="column">
      {downloadFiles.map((file) => (
        <Flex
          as="a"
          key={file.uid}
          href={file.url}
          download
          borderRadius="xl"
          backgroundColor={'orange.100'}
          p={[3, 6]}
          justifyContent={'space-between'}
          alignItems="center"
          target="_blank"
          sx={{
            transition: 'all 0.2s ease-in-out',
          }}
          _hover={{
            backgroundColor: 'orange.200',
          }}
        >
          <Text noOfLines={1} fontSize={['sm', 'md']}>
            {file.title}
          </Text>
          <Flex flex={0}>
            <Icon as={FileDown} w={6} h={6} color={'orange.400'}></Icon>
          </Flex>
        </Flex>
      ))}
    </Flex>
  </>
)

type Props = {
  uid?: string
  heading: string
  downloadFiles?: {
    uid?: string
    title?: string
    url?: string
  }[]
}

export default BlockDownloads
