import { Box, Flex, Image, Link, Text } from '@chakra-ui/react'
import l10n from '../l10n'
import React, { Fragment } from 'react'

const BlockMap = ({ uid, location, shortAddress, title = l10n.address }: Props) => {
  const shorten = (fullAddress) => {
    let splitAddress = fullAddress.split(',')

    let newAddress =
      splitAddress[1] + ' ' + splitAddress[0] + ', ' + splitAddress[5] + ' ' + splitAddress[3]

    return newAddress
  }
  return (
    <Fragment key={uid}>
      <Flex>
        {/* <Icon as={FiMapPin} fontSize="xl" color="red.400" mr={4} mt={1} /> */}
        <Box>
          <Text as={'h3'} fontWeight={'medium'} fontSize={['md', 'xl']}>
            {title}
          </Text>
          <Link
            fontSize={['md', 'lg']}
            href={`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`}
            target="_blank"
            aria-label={`Åpne kart for ${location.address}`}
            role="presentation"
            textDecoration="underline"
          >
            {shortAddress ? shorten(location.address) : location.address}
          </Link>
        </Box>
      </Flex>
      <Link
        display={'block'}
        mt={[4]}
        href={`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`}
        target="_blank"
      >
        <Box position={'relative'} height={[null, 250]}>
          <Image
            border="1px solid"
            borderColor="gray.300"
            width={'100%'}
            height={'100%'}
            objectFit={'cover'}
            objectPosition={'center'}
            borderRadius={'xl'}
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${location.lat},${location.lng}&markers=${location.lat},${location.lng}&zoom=${location.zoom}&boxSize=1280x220&scale=2&key=AIzaSyBOrqyyzRQOf4Z6Tu1KsvwWUqaBjZ8VFJ4&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=480x360`}
            alt={shorten(location.address)}
          />
        </Box>
      </Link>
    </Fragment>
  )
}

type Props = {
  uid: string
  location: {
    address: string
    lat: number
    lng: number
    zoom: number
  }
  shortAddress?: boolean
  title?: string
}

export default BlockMap
